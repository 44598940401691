<template>
  <v-container
    style="padding: 0 0 20px 0; margin: auto; border-radius: 5px; background:white;"
  >
    <v-card
      tile
      flat
      style="
          border-bottom: 2px solid rgba(0,0,0,0.1);
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          position:sticky;
          top:60px;
          background:white;
          z-index:5;
        "
    >
      <div
        style="
            display: flex;
            jsutify-content: center;
            align-items: center;
            margin-left: 10px;
          "
      >
        <v-toolbar-title class="overline">
          <p :style="`font-size:${wWidth > 780 ? '20px' : '3vw'}; margin: 0;`">
            Formulir Permohonan Izin
          </p>
        </v-toolbar-title>
      </div>
      <div>
        <v-btn
          type="button"
          rounded
          outlined
          elevation="0"
          color="red"
          class="white--text font-weight-bold ma-2"
          style="font-size:12px;"
          @click="close"
        >
          <v-icon small>mdi-chevron-left</v-icon>
          Kembali
        </v-btn>
      </div>
    </v-card>

    <v-card elevation="0">
      <v-row style="padding: 0 15px;" no-gutters>
        <v-col cols="12" md="3" style="padding: 0;">
          <div style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Nama
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;">
              <v-text-field
                dense
                outlined
                v-model="form.name"
                readonly
                style="height:45px;"
              />
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0;">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Perusahaan
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;">
              <v-autocomplete
                :items="getDropdownPlant || []"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height:45px;"
                v-model="form.company_id"
                readonly
              ></v-autocomplete>
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0;">
          <div style="padding: 0 10px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Department/ Section
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;">
              <v-autocomplete
                :items="dropdown.department"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height:45px;"
                v-model="form.department_id"
                readonly
              ></v-autocomplete>
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0;">
          <div style="padding: 0 10px;">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                Jabatan
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;">
              <v-autocomplete
                :items="dropdown.position"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height:45px;"
                v-model="form.position_id"
                readonly
              ></v-autocomplete>
            </v-col>
          </div>
        </v-col>
      </v-row>

      <v-form
        ref="entryForm"
        @submit.prevent="submit()"
        lazy-validation
        :disabled="loading"
      >
        <v-row style="padding: 15px" no-gutters>
          <v-col cols="12" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                >
                  Kategori Izin *
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0;">
                <v-autocomplete
                  :items="dropdown.type"
                  item-text="name"
                  item-value="id"
                  return-id
                  outlined
                  dense
                  style="margin: 0"
                  clearable
                  v-model="form.permission_type_id"
                  :rules="rules.typeRules"
                ></v-autocomplete>
              </v-col>
            </div>
          </v-col>

          <v-col cols="12" md="3" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                >
                  Tanggal izin *
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0;">
                <v-dialog v-model="form.start_menu" persistent width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.start_date"
                      :label="form.start_date === '' ? 'Pilih Tanggal' : ''"
                      append-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.startDateRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.start_date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="cancelStartRequest()">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      :disabled="form.start_date === ''"
                      @click="saveStartRequest()"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </div>
          </v-col>
          <v-col cols="12" md="3" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                >
                  Sampai tanggal *
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0;">
                <v-dialog v-model="form.end_menu" persistent width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.end_date"
                      :label="form.end_date === '' ? 'Pilih Tanggal' : ''"
                      append-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.endDateRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.end_date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="cancelEndRequest()">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      :disabled="form.end_date === ''"
                      @click="saveEndRequest()"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </div>
          </v-col>
          <v-col
            v-if="
              this.form.permission_type_id === 3 ||
                this.form.permission_type_id === 4 ||
                this.form.permission_type_id === 5
            "
            cols="12"
            md="3"
            style="padding: 0;"
          >
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                >
                  {{
                    this.form.permission_type_id !== 4
                      ? 'Pada jam'
                      : 'Estimasi tiba'
                  }}
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0;">
                <v-text-field
                  dense
                  outlined
                  type="time"
                  name="time"
                  step="1"
                  v-model="form.start_time"
                  :rules="rules.startTimeRules"
                />
              </v-col>
            </div>
          </v-col>
          <v-col
            v-if="this.form.permission_type_id === 3"
            cols="12"
            md="3"
            style="padding: 0;"
          >
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                >
                  Hingga jam
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0;">
                <v-text-field
                  dense
                  outlined
                  type="time"
                  name="time"
                  step="1"
                  v-model="form.end_time"
                  :rules="rules.endTimeRules"
                />
              </v-col>
            </div>
          </v-col>
          <v-col cols="12" style="padding: 0"> </v-col>
          <v-col cols="12" md="3" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                >
                  Jumlah hari
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0;" class="d-flex">
                <v-text-field
                  v-model="form.total_permission_date"
                  dense
                  outlined
                  readonly
                  :rules="rules.totalPermissionRules"
                >
                  <template v-slot:append>
                    <div>
                      <p
                        style="margin:0;font-size:14px;position:relative; top:5px;"
                      >
                        hari
                      </p>
                    </div>
                  </template>
                </v-text-field>
              </v-col>
            </div>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                >
                  Deskripsi / Keperluan / Alasan izin *
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0;">
                <v-textarea
                  dense
                  auto-grow
                  outlined
                  v-model="form.permission_reason"
                  :rules="rules.descriptionRules"
                />
              </v-col>
            </div>
          </v-col>
          <v-col
            v-if="form.permission_type_id === 1"
            cols="12"
            md="4"
            style="padding: 0;"
          >
            <div style="padding: 0 10px;">
              <v-col cols="12" style="padding: 0;">
                <p
                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                >
                  Nama tempat tujuan
                </p>
              </v-col>
              <v-col
                cols="12"
                style="padding: 0;display:flex;justify-content:center;align-items:center;margin-bottom:0;"
              >
                <v-text-field
                  dense
                  outlined
                  v-model="form.destination_name"
                  :rules="rules.destinationNameRules"
                />
              </v-col>
            </div>
          </v-col>
          <v-col
            v-if="form.permission_type_id === 1"
            cols="12"
            md="8"
            style="padding: 0"
          >
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                >
                  Alamat tempat tujuan
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0;">
                <v-textarea
                  dense
                  rows="3"
                  auto-grow
                  outlined
                  v-model="form.destination_address"
                  :rules="rules.destinationAddressRules"
                />
              </v-col>
            </div>
          </v-col>
          <v-col
            v-if="
              form.permission_type_id === 1 ||
                form.permission_type_id === 2 ||
                form.permission_type_id === 4
            "
            cols="12"
            class="d-flex justify-start align-start"
            style="padding: 0 10px;margin-bottom:20px;"
          >
            <div style="padding: 10px;border:2px dashed grey; height:70px;">
              <v-file-input
                style="width:170px;height:45px;"
                label="Upload Foto"
                dense
                prepend-icon=""
                append-icon="mdi-camera"
                :rules="rules.fileRules"
                ref="photoUpload"
                @change="uploadFoto"
                capture="camera"
                accept="image/*"
              >
              </v-file-input>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <div class="d-flex justify-center">
          <v-btn
            rounded
            outlined
            elevation="0"
            color="green"
            type="submit"
            class="white--text font-weight-bold me-5"
            style="font-size:12px;width:150px;"
            :loading="loading"
          >
            Simpan
          </v-btn>
          <v-btn
            type="button"
            rounded
            outlined
            elevation="0"
            color="red"
            @click="clearForm()"
            class="white--text font-weight-bold"
            style="font-size:12px;width:150px;"
            :loading="loading"
          >
            Hapus
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'leavesubmission',
  components: {},
  data() {
    return {
      hrsApi: buildType.apiURL('hrs'),
      upload: buildType.apiURL('upload2'),
      build: process.env.VUE_APP_BUILD_TYPE,
      loading: false,
      end_date: '2022-07-25',
      form: {
        act: 'add',
        name: '',
        company_id: null,
        department_id: null,
        position_id: null,
        permission_type_id: null,
        permission_type_name: '',
        start_date: '',
        start_menu: false,
        end_date: '',
        end_menu: false,
        start_time: '',
        end_time: '',
        total_permission_date: 0,
        permission_reason: '',
        photo: '',
        destination_name: '',
        destination_address: ''
      },
      wWidth: window.innerWidth,
      rules: {
        typeRules: [],
        descriptionRules: [],
        startDateRules: [],
        endDateRules: [],
        endTimeRules: [],
        startTimeRules: [],
        destinationNameRules: [],
        destinationAddressRules: [],
        totalPermissionRules: []
      },
      dropdown: {
        department: [],
        position: [],
        type: []
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getDropdownPlant', 'getPermissionDetail'])
  },
  async mounted() {
    this.form.name = this.getUserProfile.employee.name
    this.form.company_id = Number(this.getUserProfile.employee.company.plant_id)
    this.form.department_id = this.getUserProfile.employee.department_id
    this.form.position_id = this.getUserProfile.employee.level_id
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.initDropdown()
    if (this.getPermissionDetail !== null) {
      this.setDetailToForm()
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    this.$refs.entryForm.reset()
    this.setPermissionDetail(null)
  },
  watch: {
    'form.permission_type_id'() {
      console.log(this.form.permission_type_id)
    }
  },
  methods: {
    ...mapActions(['dropdownPlant', 'dropdownDepartment']),
    ...mapMutations(['setPermissionDetail']),
    async initDropdown() {
      await this.dropdownPlant()
      await this.dropdownDepartment()
      await this.dropdownPosition()
      await this.dropdownType()
    },
    async setDetailToForm() {
      console.log(this.getPermissionDetail.photo)
      this.form.act = 'update'
      this.form.id = this.getPermissionDetail.id
      this.form.permission_type_id = this.getPermissionDetail.permission_type.id
      this.form.permission_type_name = this.getPermissionDetail.permission_type.name
      this.form.end_date = this.getPermissionDetail.end_date
      this.form.start_date = this.getPermissionDetail.start_date
      await this.totalRequest()
      this.form.end_time = this.getPermissionDetail.end_time
      this.form.start_time = this.getPermissionDetail.start_time
      this.form.total_permission_date = this.getPermissionDetail.total_permission_date
      this.form.permission_reason = this.getPermissionDetail.permission_reason
      if (this.getPermissionDetail.photo !== null) {
        this.form.photo = this.getPermissionDetail.photo.slice(
          this.getPermissionDetail.photo.lastIndexOf('/') + 1
        )
        this.form.file = this.getPermissionDetail.photo.slice(
          this.getPermissionDetail.photo.lastIndexOf('/') + 1
        )
      }
      this.form.destination_name = this.getPermissionDetail.destination_name
      this.form.destination_address = this.getPermissionDetail.destination_address
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    clearForm(val) {
      // reset error validation
      // this.$refs.entryForm.resetValidation()

      // // reset form
      this.$refs.entryForm.reset()
      this.rules.startDateRules = []
      this.rules.endDateRules = []
      this.rules.descriptionRules = []
      this.rules.typeRules = []
      this.rules.totalPermissionRules = []
      this.rules.endTimeRules = []
      this.rules.startTimeRules = []
      this.rules.fileRules = []
      this.rules.destinationNameRules = []
      this.rules.destinationAddressRules = []
    },
    submit() {
      const self = this
      this.rules.startDateRules = [v => !!v || 'Date is required']
      this.rules.endDateRules = [v => !!v || 'Date is required']
      this.rules.descriptionRules = [v => !!v || 'Description is required']
      this.rules.typeRules = [v => !!v || 'Permit type is required']
      this.rules.totalPermissionRules = [
        v => !!v || 'Total days taken is required'
      ]

      if (
        this.form.permission_type_id === 3 ||
        this.form.permission_type_id === 4 ||
        this.form.permission_type_id === 5
      ) {
        this.rules.startTimeRules = [v => !!v || ' Time is required']

        if (this.form.permission_type_id === 3) {
          this.rules.endTimeRules = [v => !!v || 'Time is required']
        }
      }
      if (this.form.permission_type_id !== 3) {
        if (this.form.photo === '') {
          this.rules.fileRules = [v => !!v || 'File is required']
        }
        if (this.form.permission_type_id === 1) {
          this.rules.destinationNameRules = [
            v => !!v || 'Destination name is required'
          ]
          this.rules.destinationAddressRules = [
            v => !!v || 'Destination address is required'
          ]
        }
      }

      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const type_name = this.dropdown.type.find(
        ({ id }) => id === this.form.permission_type_id
      )
      const form = {
        act: this.form.act,
        id: this.form.id,
        permission_type_id: this.form.permission_type_id,
        permission_type_name: type_name.name,
        start_date: this.form.start_date,
        end_date: this.form.end_date,
        start_time: this.form.start_time,
        end_time: this.form.end_time,
        total_permission_date: this.form.total_permission_date,
        permission_reason: this.form.permission_reason,
        destination_name: this.form.destination_name,
        destination_address: this.form.destination_address,
        photo: this.form.photo
      }
      console.log(form)
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/permission/save`, form)
        .then(res => {
          console.log(res.data)
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.clearForm()
            this.showMsgDialog('success', res.data.status_msg, false).then(
              response => {
                setTimeout(() => {
                  this.setPermissionDetail(null)
                  this.$router.push(
                    `/hr/permission/detail/${
                      this.form.act === 'add'
                        ? res.data.created_id
                        : this.form.id
                    }`
                  )
                }, 500)
              }
            )
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
            return null
          }
          return null
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    async saveStartRequest() {
      this.form.end_date = this.form.start_date
      await this.totalRequest()
      this.form.start_menu = false
    },
    cancelStartRequest() {
      this.form.start_menu = false
    },
    async saveEndRequest() {
      await this.totalRequest()
      this.form.end_menu = false
    },
    cancelEndRequest() {
      this.form.end_menu = false
    },
    async totalRequest() {
      this.form.total_permission_date = 0

      await axios
        .post(`${this.hrsApi}employee/leave/calculate_business_date`, this.form)
        .then(res => {
          this.loading = false
          if (res.data.status_code === '00') {
            return (this.form.total_permission_date =
              this.form.total_permission_date + res.data.business_date_count)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              return this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              return this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },
    uploadFoto(event) {
      if (event !== null) {
        this.loading = true
        const data = new FormData()
        data.append('file', event)
        data.append('module', 'photo.permission')
        axios
          .post(`${this.upload}esanqua/hris`, data)
          .then(res => {
            console.log(res)
            if (res.data.status === true) {
              this.showMsgDialog('success', res.data.message, false)
              this.form.photo = res.data.data.name
            } else {
              this.showMsgDialog('warning', res.data.message, false)
            }
            this.loading = false
          })
          .catch(err => {
            this.showMsgDialog(
              'error',
              err
                ? 'Something went wrong, Please contact an admin!'
                : 'Something went wrong, Please contact an admin!',
              false
            )
            this.loading = false
          })
      } else {
        this.form.photo = ''
      }
    },
    close() {
      this.$refs.entryForm.reset()
      this.setPermissionDetail(null)
      if (this.prevRoute !== undefined) {
        this.$router.push(this.prevRoute.path)
      } else {
        this.$router.push('/hr/permission')
      }
    },

    async dropdownDepartment() {
      await axios
        .get(`${this.hrsApi}master/universal/department/dropdown`)
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.dropdown.department = res.data.data)
          }
          return (this.dropdown.department = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    },

    async dropdownPosition() {
      await axios
        .get(`${this.hrsApi}master/universal/employeelevel/dropdown`)
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.dropdown.position = res.data.data)
          }
          return (this.dropdown.position = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.position = [])
        })
    },

    async dropdownType() {
      await axios
        .get(`${this.hrsApi}master/universal/permissiontype/dropdown`)
        .then(res => {
          if (res.data.status_code === '00') {
            return (this.dropdown.type = res.data.data)
          }
          return (this.dropdown.type = [])
        })
        .catch(err => {
          console.log(err)
          return (this.dropdown.type = [])
        })
    }
  }
}
</script>
